import { useCallback, useEffect, useRef, useState } from 'react'
import {
  usePostMemoAction,
  useUpdateMemoAction,
  useDeleteMemoAction,
  UpdateMemoParams,
  PostMemoParams,
  usePinMemoAction
} from '@commonstock/common/src/api/memo'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { TagItemType } from '@commonstock/common/src/types'
import { Tag, useGetItemTags } from '@commonstock/common/src/api/tag'
import { useUpdateTags } from '../profile/EditTags'
import { ShareType, useGetLinkPreviewAction } from '@commonstock/common/src/api/link'

type MemoData = UpdateMemoParams | PostMemoParams
export function useCreateOrUpdateMemo() {
  const postMemo = usePostMemoAction()
  const putMemo = useUpdateMemoAction()

  const createOrUpdateMemo = useCallback(
    (data: MemoData) => {
      // @ts-ignore ts doesnt infer we are in the correct case as it should based on the existence of the uuid property
      return data.json.uuid ? putMemo(data) : postMemo(data)
    },
    [putMemo, postMemo]
  )

  return createOrUpdateMemo
}

export function useDeleteMemo() {
  const deleteMemo = useDeleteMemoAction()

  const removeMemo = useCallback(
    ({ uuid }: { uuid: string }) => {
      return deleteMemo({ meta: { uuid } })
        .then(data => data)
        .catch(err => {
          console.error('## deleteMemo err:', err)
          // @TODO implement real error handling
          alert('Something went wrong. Please try again.')
          return err
        })
    },
    [deleteMemo]
  )

  return removeMemo
}

export function useRefetchMemoDependents(ignoreFeed?: boolean) {
  const refetchDependents = useRefetchByKeys(
    'memo',
    'trade',
    'following-feed',
    'users-memos',
    'asset-trades',
    'user-trades',
    'get-tagged-content',
    ignoreFeed ? '' : 'get-global-feed'
  )
  return refetchDependents
}

type UseEditMemoTagsProps = {
  parentUuid?: string
  parentType: TagItemType
}
export function useEditMemoTags({ parentUuid = '', parentType }: UseEditMemoTagsProps) {
  const previousUuidRef = useRef(parentUuid)
  const [fetchTags] = useGetItemTags({ meta: { uuid: parentUuid } }, { paused: !parentUuid })
  const [tags, setTags] = useState<Tag[]>([])

  useEffect(() => {
    if (parentUuid !== previousUuidRef.current) {
      previousUuidRef.current = parentUuid
      fetchTags && setTags(fetchTags)
    }
  }, [fetchTags, parentUuid])

  const updateTags = useUpdateTags()
  const saveTags = useCallback(async (uuid: string) => await updateTags(fetchTags || [], tags, uuid, parentType), [
    tags,
    fetchTags,
    parentType,
    updateTags
  ])

  const removeTag = useCallback((tag: Tag) => setTags(tags.filter(t => t.canonical_name !== tag.canonical_name)), [
    tags
  ])

  return { tags, setTags, saveTags, removeTag }
}

export function useUpdateLinkPreview() {
  const fetchLinkPreview = useGetLinkPreviewAction()

  const updateLinkPreview = useCallback(
    (type: ShareType.MEMO_PLUS | ShareType.TRADE, uuid: string, skip_cache: boolean) => {
      fetchLinkPreview({ meta: { type, uuid }, query: { skip_cache } }).catch(fail => {
        // Silently fails
        console.error('## could not create share link', fail)
      })
    },
    [fetchLinkPreview]
  )

  return updateLinkPreview
}

export function usePinMemo() {
  const pinMemo = usePinMemoAction()
  const pinnedMemo = useCallback(
    ({ uuid, pinned }: { uuid: string; pinned: boolean }) => {
      return pinMemo({ meta: { uuid }, json: { pinned } })
        .then(data => data)
        .catch(err => {
          console.error('## pinMemo err:', err)
          // @TODO implement real error handling
          alert('Something went wrong. Please try again.')
          return err
        })
    },
    [pinMemo]
  )
  return pinnedMemo
}
