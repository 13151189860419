import React, { useEffect, useRef } from 'react'
import { CommentSummary, ParentTypes } from '@commonstock/common/src/api/comment'
import { BrandSpinner } from '../../components/Spinner'
import Comments from './Comments'
import { SpacerV } from '../../composing/Spacing'
import { CardLayoutTypes } from '../feed/constants'
import HorizontalStroke from 'src/composing/HorizontalStroke'

type Props = {
  initialComments?: CommentSummary
  uuid: string
  type: ParentTypes
  isModal: boolean
  rootUserUuid: string
  cardLayout: CardLayoutTypes
}
function RootComments({ uuid, type, isModal, initialComments, rootUserUuid, cardLayout }: Props) {
  const commentsRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (location.hash === `#comments`) {
      requestAnimationFrame(() => {
        commentsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!initialComments) return <BrandSpinner size={'2rem'} />
  return (
    <>
      <div ref={commentsRef} />
      <SpacerV />
      <HorizontalStroke />
      <Comments
        comments={initialComments.comments}
        uuid={uuid}
        type={type}
        rootUuid={uuid}
        rootType={type}
        isModal={isModal}
        rootUserUuid={rootUserUuid}
        cardLayout={cardLayout}
      />
    </>
  )
}

export default RootComments
