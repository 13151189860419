import { useEffect, useCallback, useRef } from 'react'
import { Routes, ShareQueryActions } from './constants'
import { useClient } from '@commonstock/client/src/react/context'
import { useAuth } from '../auth/AuthContext'
import { setPersistedState, usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { useChannels } from '../chat/ChannelContext'
import { useChat } from '../chat/ChatContext'
import { SelectedMessageIdKey } from '../chat/constants'
import useMobileAppLink, { isIphone } from '../mobile/useMobileAppLink'
import { trackUTMCampaign } from '../analytics/events.v1/utm'
import { useRouter } from 'next/router'
import { AuthTokensKey } from '../auth/constants'
import { useGoToHome } from './utils'

export const ChannelInviteKey = 'cs:channel-invite'
export const UserInviteKey = 'cs:user-invite'

const mobileRoutesRe = /^\/(asset|memo|profile|trade|login|post)/
// Controller to reset scroll and renew the client on nav
export default function RouteController() {
  const { authenticated } = useAuth()
  const router = useRouter()
  const [, setTokens] = usePersistedState<string | null>(ChannelInviteKey, null)
  const [, setInviter] = usePersistedState<string | null>(UserInviteKey, null)
  const { getUpdatedChannelAndSelect } = useChannels()
  const { isConnected } = useChat()
  const appLinkModal = useMobileAppLink()
  const prevRouteRef = useRef<string | null>(null)

  useEffect(() => {
    const prevRoute = prevRouteRef.current
    const currentRoute = router.pathname
    const shallowPath = router.asPath
    const isShallowRoute = shallowPath !== currentRoute
    const triggerMobile =
      (localStorage.getItem(AuthTokensKey) || currentRoute === Routes.Login) &&
      (mobileRoutesRe.test(isShallowRoute ? shallowPath : currentRoute) ||
        (!isShallowRoute && currentRoute === Routes.Home))
    prevRouteRef.current = currentRoute

    if (!isIphone || prevRoute === currentRoute || !triggerMobile) return
    appLinkModal(isShallowRoute ? shallowPath : currentRoute)
  }, [appLinkModal, router])

  const client = useClient()
  useEffect(() => {
    document.getElementById('main-scroll-container')?.scrollTo(0, 0)
    if (Date.now() - client.cache.renewTime > 5000) client.cache.renew()
  }, [router.pathname, client.cache])

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search)
    trackUTMCampaign(searchParams)

    // handle invites on any url
    let inviteToken = searchParams.get(ShareQueryActions.Invite)
    if (inviteToken) {
      setTokens(inviteToken)
    }

    // only trigger other share actions when the basepath is share
    if (!location.pathname.startsWith('/share')) return

    let tradeId = searchParams.get(ShareQueryActions.Trade)
    let memoId = searchParams.get(ShareQueryActions.Memo)
    let inviter = searchParams.get(ShareQueryActions.Inviter)
    let tokens = searchParams.get(ShareQueryActions.Tokens)

    if (tradeId) {
      router.replace(Routes.tradeDetail(tradeId))
    } else if (memoId) {
      router.replace(Routes.memoDetail(memoId))
    } else if (inviter) {
      setInviter(inviter)
      tokens && setTokens(tokens)
    }
  }, [authenticated, setInviter, setTokens, router])

  const selectChannelAndMessage = useCallback(
    (channel: string, comment: number) => {
      if (comment) setPersistedState(SelectedMessageIdKey, String(comment))
      if (!isConnected) setTimeout(() => selectChannelAndMessage(channel, comment), 500)
      else getUpdatedChannelAndSelect(channel)
    },
    [getUpdatedChannelAndSelect, isConnected]
  )

  const { goToHome } = useGoToHome()
  // deal with push notification redirections
  useEffect(() => {
    const chatMatch = window.location.pathname.match(/\/channels?\/([^/]+)\/?(.*)?/)
    if (chatMatch) {
      goToHome()
      selectChannelAndMessage(chatMatch[1], Number(chatMatch[2]))
    }
  }, [goToHome, router, selectChannelAndMessage])

  return null
}
