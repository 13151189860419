import React from 'react'
import { SpacerV } from '../../composing/Spacing'
import TradeHeader from './TradeHeader'
import CardCommentSummary from '../../components/CardCommentSummary'
import { ParentTypes } from '@commonstock/common/src/api/comment'
import { TradeFeedItem } from '@commonstock/common/src/api/feed'
import LinkedMemos from 'src/scopes/memo/LinkedMemos'
import { destructTradeFeedItem } from './utils'
import { useTrackFeedItemImpression } from '../analytics/events.v1'
import LinkedPosts from '../posts/LinkedPosts'
import { useInView } from 'react-intersection-observer'
import ContentHeader from '../content/ContentHeader'
import ContentFooterActions from '../content/ContentFooterActions'
import { TradeReason } from './TradeReason'
import { CardContentWrapper } from '../content/DetailContentWrapper'
import { CardLayoutTypes } from '../feed/constants'

type Props = {
  tradeFeedItem: TradeFeedItem
  portfolio?: boolean
  cardLayout: CardLayoutTypes
  commentTarget?: string
}

function TradeCard({ tradeFeedItem, portfolio, commentTarget, cardLayout }: Props) {
  const [inViewRef, inView] = useInView({ triggerOnce: true })
  const { trade } = destructTradeFeedItem(tradeFeedItem)

  useTrackFeedItemImpression(tradeFeedItem, inView)

  if (!trade) return null
  return (
    <CardContentWrapper ref={inViewRef} feedItem={tradeFeedItem} cardLayout={cardLayout}>
      <TradeContent tradeFeedItem={tradeFeedItem} portfolio={portfolio} cardLayout={cardLayout} />
      {!!trade.comments?.count && (
        <CardCommentSummary
          comments={trade.comments}
          parentType={ParentTypes.TRADE}
          commentTarget={commentTarget}
          parentUuid={trade.uuid}
          feedItem={tradeFeedItem}
          cardLayout={cardLayout}
        />
      )}
    </CardContentWrapper>
  )
}

type TradeContentProps = {
  tradeFeedItem: TradeFeedItem
  portfolio?: boolean | undefined
  detail?: boolean
  cardLayout: CardLayoutTypes
}
export function TradeContent({ tradeFeedItem, portfolio, detail, cardLayout }: TradeContentProps) {
  const { user, linkedMemos, linkedPosts } = destructTradeFeedItem(tradeFeedItem)

  if (!user) return null
  return (
    <>
      <ContentHeader user={user} feedItem={tradeFeedItem} detail={!!detail} />
      <SpacerV />
      <TradeHeader tradeFeedItem={tradeFeedItem} isPortfolio={!!portfolio} />
      <SpacerV rem={0.75} />
      <LinkedPosts tradeFeedItem={tradeFeedItem} posts={linkedPosts} />
      <LinkedMemos tradeFeedItem={tradeFeedItem} memos={linkedMemos} />
      <TradeReason tradeFeedItem={tradeFeedItem} user={user} />
      <ContentFooterActions feedItem={tradeFeedItem} detail={detail} cardLayout={cardLayout} />
    </>
  )
}

export default React.memo(TradeCard)
