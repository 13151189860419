import { MemoFeedItem, PostFeedItem, TradeFeedItem, TradeResource } from '@commonstock/common/src/api/feed'
import { useMemo } from 'react'
import LinkedResourceCarousel from 'src/components/LinkedResourceCarousel'
import { SpacerV } from 'src/composing/Spacing'
import { ContentDetailLink } from '../content/DetailContentWrapper'
import { CardLayoutTypes } from '../feed/constants'
import { feedItemToTrade } from '../memo/utils'
import TradeHeader from './TradeHeader'

type Props = {
  feedItem: MemoFeedItem | PostFeedItem
  trades: TradeResource[]
}

function LinkedTrades({ feedItem, trades }: Props) {
  const tradeFeedItems: TradeFeedItem[] = useMemo(() => {
    return trades.map(trade => feedItemToTrade(feedItem, trade)).filter((item): item is TradeFeedItem => !!item)
  }, [feedItem, trades])

  if (!tradeFeedItems.length) return null
  return (
    <>
      <SpacerV />
      <LinkedResourceCarousel label="trades" height={5.6875}>
        {tradeFeedItems.map(tradeFeedItem => (
          <ContentDetailLink key={tradeFeedItem.uuid} feedItem={tradeFeedItem} cardLayout={CardLayoutTypes.Default}>
            <TradeHeader tradeFeedItem={tradeFeedItem} isLinkedTrade />
          </ContentDetailLink>
        ))}
      </LinkedResourceCarousel>
    </>
  )
}

export default LinkedTrades
