import React, { useCallback } from 'react'
import { useUpdateAlertPreferencesAction } from '@commonstock/common/src/api/profile'
import { MenuItem } from '../../components/styles'
import { reportFailedResource } from '../../dev/sentry'

import AnimatedEllipsis from '../../components/AnimatedEllipsis'
import useFetchSubmit from '../../hooks/useFetchSubmit'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { TradeFeedItem } from '@commonstock/common/src/api/feed'

function MuteTrades({ tradeFeedItem }: { tradeFeedItem: TradeFeedItem }) {
  const { submit, pending, fail, success } = useFetchSubmit(useUpdateAlertPreferencesAction(), reportFailedResource)
  const refetchDependents = useRefetchByKeys('following-feed', 'get-global-feed', 'user-profile', 'profile-username')

  const onClick = useCallback(async () => {
    await submit({
      meta: { uuid: tradeFeedItem.user_uuid },
      json: { memo_alerts_enabled: true, trade_alerts_enabled: false }
    })
    refetchDependents()
  }, [refetchDependents, submit, tradeFeedItem.user_uuid])

  return (
    <MenuItem disabled={!!(pending || success)} onClick={pending || success ? undefined : onClick}>
      {fail ? 'Muting Failed' : success ? 'Muted' : 'Mute trades'}
      {pending && <AnimatedEllipsis />}
    </MenuItem>
  )
}

export default MuteTrades
